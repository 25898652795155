<template>
  <div class="messageReturn">
    <div class="title"> 意见反馈</div>
    <div class="content">
      <div class="textarea_box">
        <textarea placeholder="为了更好的帮助你解决问题，请在提交问题时同时上传截图信息，方便我们更快速的帮助您解决题。" v-model="sumitObj.content"></textarea>
      </div>
      <div class="upload_img_box">
        <div class="upload_title">
          上传图片
        </div>
        <div class="upload_content">
          <div class="photos">
            <div class="photo_list">
              <div class="photo_item" v-for="(photo,index) in sumitObj.images" :key="index">
                <IImage :src="photo" />
                <div class="del_photo_btn" @click="delPhoto(index)">
                  <img src="@/assets/img/icon/icon_radio_close.png" alt="">
                </div>
              </div>
            </div>
            <div class="photo_upload" v-if="sumitObj.images.length<3">
              <uploadImage stats="images" @success="uploadSuccess">
                <img src="@/assets/img/icon/bg_upload_img.png" alt="" slot="uploadContent">
              </uploadImage>
            </div>
          </div>
        </div>
      </div>
      <button class="submit" @click="submitMessage">立即提交</button>
    </div>
  </div>
</template>

<script>
import uploadImage from '../../../components/uploadImage/uploadImage.vue';

export default {
  components: {
    uploadImage
  },
  data () {
    return {
      sumitObj: {
        content: '',
        images: [],
      }
    }
  },
  mounted () {

  },
  methods: {
    uploadSuccess (val) {
      this.sumitObj[val.status].push(val.img);
    },
    delPhoto (index) {
      this.sumitObj.images.splice(index, 1);
    },
    submitMessage () {
      let parmas = {
        ...this.sumitObj
      }
      parmas.images = parmas.images + '';
      this.$api.suggestion(parmas).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          setTimeout(() => {
            this.sumitObj = {
              content: '',
              images: [],
            }
          }, 1000);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.messageReturn {
  padding: 21px 17px 0;
  background: #fff;
  border-radius: 8px 8px 8px 8px;
  height: 807px;
  .title {
    font-size: 22px;
    font-weight: 500;
    color: #2f2f2f;
    line-height: 26px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e5e5e5;
  }

  .content {
    margin: 10px 5px 5px;
    .textarea_box {
      textarea {
        width: 100%;
        height: 300px;
        border-radius: 5px 5px 5px 5px;
        margin: 10px 0 32px;
        padding: 13px 14px;
        border: 1px solid #e5e5e5;
        outline-style: none;

        &::-webkit-input-placeholder {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
        }
      }
    }
    .upload_img_box {
      margin-bottom: 40px;
      .upload_title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 17px;
        margin-bottom: 10px;
      }
      .photos {
        display: flex;
        margin: 19px 0 35px;
        .photo_list {
          display: flex;
          .photo_item {
            width: 140px;
            height: 140px;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            border: 1px solid #e5e5e5;
            margin-right: 22px;
            overflow: hidden;
            position: relative;
            img {
              width: 100%;
              height: 100%;
            }
            .del_photo_btn {
              position: absolute;
              top: 8px;
              right: 14px;
              cursor: pointer;
              img {
                width: 22px;
                height: 22px;
              }
            }
          }
        }

        .photo_upload {
          img {
            width: 140px;
            height: 140px;
            border-radius: 8px 8px 8px 8px;
          }
        }
      }
    }

    .submit {
      padding: 16px 185px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
      background: #3f63ff;
      border-radius: 10px 10px 10px 10px;
      cursor: pointer;
      margin: 0 auto;
    }
  }
}
</style>